* {
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by any browser but < IE9 */
}

html,
body {
    position: relative;
    overflow: hidden;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

/* 顶部性别切换按钮 */
.top-buttons {
    position: fixed;
    top: 4%;
    left: 50%; /* 将元素移动到父元素的中心 */
    transform: translateX(-50%); /* 使用X轴的变换将元素向左移动其自身宽度的一半 */
    display: flex;
    justify-content: space-between; /* 使按钮分散到容器的两侧 */
    width: 240px; /* 你可能需要根据实际需求调整这个宽度 */
    padding: 10px;
    gap: 10px;
    z-index: 10;
}


.toggle-button {
    background: none;
    border: none;
    height: 30px;
    width: 72px;
    padding: 10px 10px;
    cursor: pointer;
    outline: none;
    position: relative;
    color: rgb(138, 138, 138); /* 非选中状态下的字体颜色 */
    font-family: Arial, sans-serif;
    transition: color 0.3s; /* 仅添加颜色过渡效果 */
}

.toggle-button:focus {
    outline: none;
    box-shadow: none;
}

/* 底部圆角横线 */
.toggle-button.selected::after {
    content: "";
    position: absolute;
    bottom: -5px; /* 调整位置以使线条完全可见 */
    left: 10%; /* 调整开始位置 */
    width: 80%; /* 调整横线宽度 */
    height: 2px; /* 增加高度以适应圆角 */
    background-color: rgb(246, 33, 152);
    border-radius: 2px; /* 设置圆角 */
}

/* 性别按钮选中状态的字体颜色 */
.toggle-button.selected {
    color: rgb(246, 246, 246);
}

/* 顶部滑动栏 */
.swiper-container {
    width: 140%;
    left: -18%;
    position: fixed;
    height: 230px;
    top: 13%;
    /*border: 1px solid red;*/
}

.swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.swiper-slide img {
    width: 80%;
    height: auto;
    object-fit: cover;
    position: relative;
    bottom: 0;
    border-radius: 10px;
}

/* 模型加载进度条 */
.progress-container {
    position: fixed;
    top: 53.6%;
    left: 50%;
    width: 120px;
    height: 4px;
    background-color: #FFFFFF; /* 设置底色为白色 */
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 2px; /* 设置圆角效果 */
}

.progress-bar {
    width: 0;
    height: 100%;
    background-color: #F62198; /* 设置进度颜色为#F62198 */
    border-radius: 2px; /* 设置圆角效果 */
}


/* 左右切换的按钮 */
.swiper-prev, .swiper-next {
    position: fixed;
    top: 54%;
    transform: translateY(-50%); /* 垂直居中 */
    width: auto;
    height: auto;
    cursor: pointer; /* 鼠标悬停时显示手形 */
    z-index: 10; /* 确保按钮在其他元素之上 */
}

.swiper-prev {
    left: 12%; /* 左侧 */
}

.swiper-next {
    right: 12%; /* 右侧 */
}

.next-button {
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);

    width: 90%;
    height: 40px;
    border-radius: 20px;
    background-color: #F62198;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    z-index: 10;
}

/* 底部切换圆点 */
.dots-container {
    display: flex;
    gap: 15px;
    overflow: hidden;
    width: 320px;
    height: 60px;
    justify-content: center;
    position: fixed;
    bottom: 18.8%;
    left: 50%;
    transform: translate(-50%, 50%);
    /*border: 1px solid red;*/
    align-items: center; /* 上下居中 */
}

.mask {
    display: flex;
    gap: 15px;
    width: 120px;
    height: 40px;
    /*background-color: rgb(69, 69, 69);*/
    border-radius: 25px;
    overflow: hidden; /* This will act as our mask */
    position: relative;
    /*border: 1px solid red;*/

}

.dots-container::before {
    content: ""; /* 创建伪元素 */
    background-color: rgb(69, 69, 69); /* 设置背景颜色 */
    border-radius: 25px; /* 设置圆角 */
    width: 120px; /* 伪元素宽度 */
    height: 40px; /* 伪元素高度 */
    position: absolute; /* 使用绝对定位 */
    top: 50%; /* 垂直居中 */
    left: 50%; /* 水平居中 */
    transform: translate(-50%, -50%); /* 确保完全居中 */
    z-index: -1; /* 确保在内容之下 */
    opacity: 0; /* 添加这一行 */
    transition: opacity 0.5s; /* 添加这一行，为了平滑过渡效果 */
}

.dots-container.show-before::before {
    opacity: 1; /* 当添加了 show-before 类时，使其可见 */
}

.dots-wrapper {
    display: flex;
    gap: 15px;
    width: calc(5 * 10px + 4 * 15px);
    align-items: center; /* Vertically center the dots */
    /*border: 1px solid red;*/
}

.dot {
    width: 8px;
    height: 8px;
    background-color: rgb(138, 138, 138); /* 默认灰色 */
    border-radius: 50%;
    transition: background-color 0.3s; /* 添加颜色过渡效果 */
}

.dot.active {
    background-color: rgb(246, 33, 152); /* 选中状态颜色 */
}

.dot, .dot.active {
    box-sizing: border-box;
    vertical-align: middle;
}


/*.animation-name {*/
/*    position: absolute;*/
/*    bottom: 128px; !* 根据你的需求调整这个值，使其位于圆点的上方 *!*/
/*    height: 18px;*/
/*    left: 50%; !* 水平居中 *!*/
/*    transform: translateX(-50%); !* 修正 left 50% 带来的偏移 *!*/
/*    z-index: 5;*/
/*    color: white;*/
/*    font-size: 16px;*/
/*    padding: 10px;*/
/*}*/

a[href="./personal.html"] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* 让它显示在最前面 */
    color: #ffffff; /* 白色文本 */
    text-decoration: none; /* 取消下划线 */
    padding: 10px 20px; /* 为链接添加一些内边距 */
    background-color: rgba(0, 0, 0, 0.7); /* 添加半透明的黑色背景以增强可读性 */
    border-radius: 5px; /* 轻微的圆角 */
}


/* 基本样式，确保所有元素都是块级元素，并且使用绝对定位 */
#flollowers-num,
#flollowers,
#follwing-num,
#follwing,
#posts-num,
#posts {
    position: fixed;
    display: block;
    /*position: absolute;*/
    left: 7.3%;
    z-index: 99;
    font-size: 15px;
}

/* 设置-num元素的文字颜色为灰色 (68, 68, 68) */
#flollowers-num,
#follwing-num,
#posts-num {
    color: rgb(68, 68, 68);
}

/* 设置其他元素的文字颜色为白色 (230, 230, 230) */
#flollowers,
#follwing,
#posts {
    color: rgb(230, 230, 230);
}


/* 设置起始位置 */
#flollowers-num {
    top: 19.3%;
}

/* 根据第一个元素的位置，为其他元素设置相对的偏移 */
#flollowers {
    top: 21.3%;
}

#follwing-num {
    top: 24.9%;
}

#follwing {
    top: 26.9%;
}

#posts-num {
    top: 30.5%;
}

#posts {
    top: 32.5%;
}


/* 人物对话框 */
.rounded-rectangle {
    display: block;
    position: fixed;
    right: -4%;
    padding: 0; /* 根据需要调整内边距 */
    top: 19.2%;
    width: 180px;
    height: 132px;
    background-color: transparent; /* 设置背景为透明 */
    z-index: 95;
    /*border: 1px solid green;*/

}

#rounded-rectangle-text {
    position: absolute; /* 使用绝对定位将文本放置在对话框内部 */
    top: 50%; /* 垂直居中 */
    left: 50%; /* 水平居中 */
    transform: translate(-50%, -50%); /* 使用transform进行微调，确保完全居中 */
    width: 66%; /* 设置宽度为对话框宽度的80%，可以根据需要调整 */
    height: 66%;
    text-align: center; /* 文本居中对齐 */
    color: white; /* 设置文本颜色为白色，可以根据需要调整 */
    word-wrap: break-word; /* 确保长文本可以换行 */
    /*position: fixed;*/
    /*top: 19.3%;*/
    /*right: 60px;*/
    /*width: 120px; !* 设置矩形的宽度。文字将基于这个宽度自动换行 *!*/
    /*height: 120px;*/
    /*margin: 15px 15px 40px 20px;*/
    /*!*padding: 0 0 10px 0; !* 根据需要调整内边距 *!*!*/
    /*color: white; !* 设置文字颜色。这里我选择了白色，但您可以根据需要修改 *!*/
    /*word-wrap: break-word; !* 确保文字在必要时换行 *!*/
    z-index: 100;
    font-size: 13px;
    overflow: hidden; /* 隐藏超出的内容 */
    display: -webkit-box; /* 使用flexbox模型 */
    -webkit-line-clamp: 6; /* 限制在3行 */
    -webkit-box-orient: vertical; /* 设置flexbox的方向为垂直 */
    text-overflow: ellipsis; /* 这里仍然设置为ellipsis，尽管它只在单行文本上有效 */
    clip-path: inset(0 0 15% 0);
    /*border: 1px solid red;*/
}


.rounded-rectangle > svg {
    width: 180px;
    height: 132px;
    position: fixed;
    z-index: 99;
}


/* 对照UI位置用 */
#overlaytest {
    position: fixed; /* 使用固定定位，使其始终覆盖全屏 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../static/1693995213954.jpg'); /* 替换为您的图片路径 */
    background-size: cover; /* 确保图片覆盖整个屏幕 */
    background-position: center center; /* 使图片居中显示 */
    opacity: 0.6; /* 设置半透明度 */
    z-index: 50; /* 使用一个高的z-index值，确保图片位于其他内容的前面 */
    pointer-events: none;
}
